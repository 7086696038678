
import { defineComponent, onMounted } from "vue";
import { uploadSpaceLocationImages } from "@/core/services/api/file";
import localstorage from "@/core/services/LocalstorageService";
import { getSpacesByManager } from "@/core/services/api/spaces";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";

export default defineComponent({
  props: ["previewFile"],
  setup(props, { emit }) {
    let previewModal;
    const router = useRouter();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const getFileUrl = (file) => {
      if (file !== null && file !== undefined) {
        const fileUrl = URL.createObjectURL(file);
        return fileUrl;
      }
    };

    const uploadFile = async () => {
      let filesLength = props.previewFile.length;
      for (let index = 0; index < props.previewFile.length; index++) {
        let formData = new FormData();
        formData.set(
          "file",
          props.previewFile[index],
          props.previewFile[index].name
        );
        await uploadSpaceLocationImages(currentSpaceGuid!, formData).then(
          (res: any) => {
            if (res.result) {
              if (filesLength == index + 1) {
                getSpacesByManager().then((spaces) => {
                  localstorage.saveSpaces(spaces);
                  let currentSpaces: any = spaces.filter(
                    (o) => o.space.guid == currentSpaceGuid!
                  );
                  localstorage.saveCurrentSpaces(currentSpaces[0]);
                  window.location.reload() ;
                });
              }
            }
          }
        );
      }
    };

    const cancel = () => {
      previewModal.hide();
      emit("cancelPreview");
    };

    onMounted(() => {
      previewModal = new Modal(document.getElementById("previewLocationImg-modal"));
    });
    return {
      getFileUrl,
      props,
      uploadFile,
      cancel,
    };
  },
});
