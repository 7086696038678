<template>
  <div class="row mb-10">
    <div class="col">
      <div class="input-group">
        <input
          multiple
          type="file"
          class="form-control"
          id="inputGroupFile04"
          accept="image/jpeg"
          aria-describedby="inputGroupFileAddon04"
          aria-label="Upload"
          @change="viewData($event)"
        />
      </div>
      <div class="mt-2" style="color: red; font-size: 16px">
        ※上傳圖片解析請於 1920 x 1080 以內。
      </div>
      <div class="mt-2" style="color: red; font-size: 16px">
        ※拖動下方圖片以改變順序，排序第一為封面照。
      </div>
    </div>
    <div class="col d-flex justify-content-end align-items-start">
      <button
        type="button"
        @click="storageSort()"
        class="btn btn-primary"
        style="height: 42.5px"
      >
        儲存排序
      </button>
    </div>
  </div>

  <div class="mb-10">
    <draggable
      class="p-0"
      style="width: 100%"
      tag="transition-group"
      :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      :list="spaceThemeImgs"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="order"
    >
      <template #item="{ element, index }">
        <div class="draggableImg me-10">
          <i class="fs-4 ms-1">{{ index + 1 }}</i>
          <div
            class="
              btn btn-sm btn-icon btn-color-danger btn-active-color-danger
              iconX
            "
            @click="remove(element)"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <img
            style="width: 225px; height: 168px"
            :src="getImgPath(element)"
            class="img-fluid img-thumbnail"
            alt="..."
          />
        </div>
      </template>
    </draggable>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <template v-for="(img, index) in spaceThemeImgs" :key="img">
            <div class="carousel-item" :class="{ active: index == 0 }">
              <img :src="getImgPath(img)" class="d-block w-100" alt="..." />
            </div>
          </template>

          <!-- <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="..." />
        </div> -->
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">上一張</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">下一張</span>
        </button>
      </div>
    </div>
  </div>
  <!-- 預覽照片modal -->
  <div
    class="modal fade"
    id="preview-modal"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">預覽照片</h5>
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <div class="modal-body">
          <PreviewImg
            :previewFile="previewFile"
            @cancelPreview="cancelPreview()"
          ></PreviewImg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import draggable from "vuedraggable";
import { getFilePath } from "@/core/utils/file";
import { deleteSpaceImages } from "@/core/services/api/file";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PreviewImg from "./PreviewImg.vue";
import { Modal } from "bootstrap";
import { getSpacesByManager } from "@/core/services/api/spaces";
import { useRouter } from "vue-router";
import { updateSpace } from "@/core/services/api/spaces";

export default defineComponent({
  components: {
    draggable,
    PreviewImg,
  },
  emits: ["cancelPreview"],
  setup() {
    const currentSpace = localstorage.getCurrentSpaces()?.space;
    const router = useRouter();
    let previewFile: any = reactive([]);
    let previewModal;
    let files = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let spaceThemeImgs: any = reactive([]);
    const setSpaceThemeImgs = () => {
      let themeImgs: any = localstorage.getCurrentSpaces()?.space.theme.images;
      spaceThemeImgs.push(...themeImgs);
    };

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    });

    const getImgPath = (img) => {
      return getFilePath(img);
    };

    const remove = async (file) => {
      await Swal.fire({
        title: "您確定要刪除圖片嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteSpaceImages(currentSpaceGuid!, file).then(() => {
            getSpacesByManager().then((spaces) => {
              localstorage.saveSpaces(spaces);
              let currentSpaces: any = spaces.filter(
                (o) => o.space.guid == currentSpaceGuid!
              );
              localstorage.saveCurrentSpaces(currentSpaces[0]);
            });
          });
          Swal.fire("刪除成功!", "", "success").then(() => {
            window.location.reload() ;
          });
        }
      });
    };

    const viewData = (e) => {
      previewFile.splice(0, previewFile.length, ...e.target.files);
      console.log(filesIsEmpty());
      if (!filesIsEmpty()) {
        previewModal.show();
      }
    };

    const cancelPreview = () => {
      previewFile.splice(0);
      let obj: any = document.getElementById("inputGroupFile04");
      obj.value = "";
      previewModal.hide();
    };

    onMounted(() => {
      previewModal = new Modal(document.getElementById("preview-modal"));
    });

    const filesIsEmpty = () => {
      return previewFile.length == 0;
    };

    const storageSort = async () => {
      let updateSpaceRequest = {
        guid: currentSpace!.guid,
        name: currentSpace!.name,
        status: currentSpace!.status,
        images: spaceThemeImgs,
        contact: {
          phones: currentSpace!.contact.phones,
          emails: currentSpace!.contact.emails,
          website: currentSpace!.contact.website,
          address: currentSpace!.contact.address,
          googleMap: currentSpace!.contact.googleMap,
        },
        socialMedia: {
          facebook: currentSpace!.socialMedia.facebook,
          line: currentSpace!.socialMedia.line,
        },
        information: currentSpace!.information,
        description: currentSpace!.description,
        openingHoursDescription: currentSpace!.openingHoursDescription,
        usageNote: currentSpace!.usageNote,
      };
      await updateSpace(updateSpaceRequest).then(() => {
        getSpacesByManager().then((spaces) => {
          localstorage.saveSpaces(spaces);
          let currentSpaces: any = spaces.filter(
            (o) => o.space.guid == currentSpaceGuid!
          );
          localstorage.saveCurrentSpaces(currentSpaces[0]);
          Swal.fire("儲存成功!", "", "success");
        });
      });
      
    };

    const init = () => {
      setSpaceThemeImgs();
    };
    init();

    return {
      spaceThemeImgs,
      dragOptions,
      getImgPath,
      remove,
      viewData,
      files,
      previewFile,
      cancelPreview,
      filesIsEmpty,
      storageSort
    };
  },
});
</script>

<style scoped>
.draggableImg {
  width: 225px;
  height: 190px;
  display: inline-block;
  position: relative;
}

.iconX {
  display: none;
  position: absolute;
  top: 25px;
  left: 184px;
}

.draggableImg:hover .iconX {
  display: block;
}
</style>
