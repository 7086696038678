
import { computed, defineComponent, onMounted, reactive } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import draggable from "vuedraggable";
import { getFilePath } from "@/core/utils/file";
import { deleteSpaceImages } from "@/core/services/api/file";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PreviewImg from "./PreviewImg.vue";
import { Modal } from "bootstrap";
import { getSpacesByManager } from "@/core/services/api/spaces";
import { useRouter } from "vue-router";
import { updateSpace } from "@/core/services/api/spaces";

export default defineComponent({
  components: {
    draggable,
    PreviewImg,
  },
  emits: ["cancelPreview"],
  setup() {
    const currentSpace = localstorage.getCurrentSpaces()?.space;
    const router = useRouter();
    let previewFile: any = reactive([]);
    let previewModal;
    let files = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let spaceThemeImgs: any = reactive([]);
    const setSpaceThemeImgs = () => {
      let themeImgs: any = localstorage.getCurrentSpaces()?.space.theme.images;
      spaceThemeImgs.push(...themeImgs);
    };

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    });

    const getImgPath = (img) => {
      return getFilePath(img);
    };

    const remove = async (file) => {
      await Swal.fire({
        title: "您確定要刪除圖片嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteSpaceImages(currentSpaceGuid!, file).then(() => {
            getSpacesByManager().then((spaces) => {
              localstorage.saveSpaces(spaces);
              let currentSpaces: any = spaces.filter(
                (o) => o.space.guid == currentSpaceGuid!
              );
              localstorage.saveCurrentSpaces(currentSpaces[0]);
            });
          });
          Swal.fire("刪除成功!", "", "success").then(() => {
            window.location.reload() ;
          });
        }
      });
    };

    const viewData = (e) => {
      previewFile.splice(0, previewFile.length, ...e.target.files);
      console.log(filesIsEmpty());
      if (!filesIsEmpty()) {
        previewModal.show();
      }
    };

    const cancelPreview = () => {
      previewFile.splice(0);
      let obj: any = document.getElementById("inputGroupFile04");
      obj.value = "";
      previewModal.hide();
    };

    onMounted(() => {
      previewModal = new Modal(document.getElementById("preview-modal"));
    });

    const filesIsEmpty = () => {
      return previewFile.length == 0;
    };

    const storageSort = async () => {
      let updateSpaceRequest = {
        guid: currentSpace!.guid,
        name: currentSpace!.name,
        status: currentSpace!.status,
        images: spaceThemeImgs,
        contact: {
          phones: currentSpace!.contact.phones,
          emails: currentSpace!.contact.emails,
          website: currentSpace!.contact.website,
          address: currentSpace!.contact.address,
          googleMap: currentSpace!.contact.googleMap,
        },
        socialMedia: {
          facebook: currentSpace!.socialMedia.facebook,
          line: currentSpace!.socialMedia.line,
        },
        information: currentSpace!.information,
        description: currentSpace!.description,
        openingHoursDescription: currentSpace!.openingHoursDescription,
        usageNote: currentSpace!.usageNote,
      };
      await updateSpace(updateSpaceRequest).then(() => {
        getSpacesByManager().then((spaces) => {
          localstorage.saveSpaces(spaces);
          let currentSpaces: any = spaces.filter(
            (o) => o.space.guid == currentSpaceGuid!
          );
          localstorage.saveCurrentSpaces(currentSpaces[0]);
          Swal.fire("儲存成功!", "", "success");
        });
      });
      
    };

    const init = () => {
      setSpaceThemeImgs();
    };
    init();

    return {
      spaceThemeImgs,
      dragOptions,
      getImgPath,
      remove,
      viewData,
      files,
      previewFile,
      cancelPreview,
      filesIsEmpty,
      storageSort
    };
  },
});
