
import { defineComponent } from "vue";
import ThemePhoto from "./components/ThemePhoto.vue";
import FloorPlan from "./components/FloorPlan.vue";
export default defineComponent({
  components: {
    ThemePhoto,
    FloorPlan
  },
});
